import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import Qs from 'qs';
import Vuetify from 'vuetify';
import VueRouter from 'vue-router';
import lottie from 'vue-lottie'

import vuetify from './plugins/vuetify';
import './registerServiceWorker'
import predict from "@/components/computer/Predict";
import nfa2DFA from "@/components/computer/NFA2DFA";
import dfaMinimize from "@/components/computer/DFAMinimize";
import regex2NFA from "@/components/computer/Regex2NFA";
import home from "@/components/Home"
import calculateFirst from "@/components/computer/CalculateFirst";
import calculateFollow from "@/components/computer/CalculateFollow";
import ll1 from "@/components/computer/LL1";
import elr from "@/components/computer/ELR";
import rda from "@/components/computer/RDA";
import graph from "@/components/math/Graph";
import three from "@/components/math/Three";
import geometry from "@/components/math/Geometry";

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$qs = Qs;
Vue.prototype.$vis = require("vis-network/dist/vis-network.min")
require("vis-network/styles/vis-network.min.css")
Vue.use(Vuetify)
Vue.use(VueRouter)
Vue.component('lottie', lottie)

const routes = [
  {path: '/predict', component: predict, name: 'Predict'},
  {path: '/nfa2DFA', component: nfa2DFA, name: 'NFA2DFA'},
  {path: '/dfaMinimize', component: dfaMinimize, name: 'DFAMinimize'},
  {path: '/regex2NFA', component: regex2NFA, name: 'Regex2NFA'},
  {path: '/calculateFirst', component: calculateFirst, name: 'calculateFirst'},
  {path: '/calculateFollow', component: calculateFollow, name: 'calculateFollow'},
  {path: '/ll1', component: ll1, name: "LL1"},
  {path: '/home', component: home, name: 'Home'},
  {path: '/elr', component: elr, name: 'ELR'},
  {path: '/rda', component: rda, name: 'RDA'},
  {path: '/graph', component: graph, name: 'Graph'},
  {path: '/3d', component: three, name: 'Three'},
  {path: '/geometry', component: geometry, name: 'Geometry'},
  {path: '', component: home, name: 'Default'}
]

const router = new VueRouter({
  routes
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
