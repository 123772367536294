<template>
  <div id="math-3d">
    正在加载……
  </div>
</template>

<script>
export default {
  name: "3D",
  mounted() {
    window.injectThree()
  }
}
</script>

<style scoped>

</style>