<template>
<div class="mx-16 my-16 text-h6">即将到来……</div>
</template>

<script>
export default {
name: "RDA.vue"
}
</script>

<style scoped>

</style>