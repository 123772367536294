<template>
  <div id="math-graph">
    正在加载……
  </div>
</template>

<script>
export default {
name: "Graph",
  mounted() {
  window.injectGraph()
  }
}
</script>

<style scoped>

</style>